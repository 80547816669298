import React from 'react'
import makeTabs from '../../../helpers/areaPrivadaTabs'
import { t } from '../../../i18n'
import Header from '../../areaClientes/header/Header'
import SmallTabs from '../../areaClientes/smallTabs/SmallTabs'
import TallerCard from '../../areaClientes/tallerCard/TallerCard'
import styles from './index.module.scss'
import { connect } from 'react-redux'
import { IAppState } from '../../../context/context'
import * as UserActions from '../../../context/actions/userActions'
import { selectPercentCompleted } from '../../../context/selectors/userSelectors'
import { Dispatch } from 'redux'
import { FavouriteTaller } from '../../../types/Taller'
import { getFavouriteTaller } from '../../../context/selectors/areaPrivadaSelector'

interface Props {
  datosProgress: number
  logout: () => void
  favouriteTaller: FavouriteTaller
  children: JSX.Element[] | JSX.Element
  title: string
}
const AreaPrivadaLayout: React.FC<Props> = ({
  datosProgress,
  favouriteTaller,
  logout,
  children,
  title,
}) => {
  const tabs = makeTabs()
  return (
    <section className={styles.area_privada_container}>
      <Header logout={logout} tabs={tabs} title={title} device="desktop" />
      <section className={styles.area_clientes_main}>
        <div className={styles.area_clientes_left}>
          {children}
          <h3 className={styles.mobile_tabs_title}>{t('historico.ir_a')}</h3>
          <SmallTabs tabs={tabs} logout={logout} device="mobile" />
        </div>
        <div className={styles.area_clientes_right}>
          {datosProgress && (
            <div className={styles.progressBar}>
              <p>{t('mis-datos.completa_datos')}</p>
              <div className={styles.progress_bar}>
                <progress
                  style={{ height: '20px', marginBottom: '10px' }}
                  value={datosProgress || 0}
                  max="100">
                  15%
                </progress>
              </div>
              <p className={styles.progressBar_text}>
                {t('mis-datos.porcentaje_completado', {
                  percent: datosProgress.toFixed(),
                })}
              </p>
            </div>
          )}
          {favouriteTaller && (
            <div className={styles.talleres}>
              <h4 className={styles.talleres_title}>
                {t('area_privada.right_section.talleres')}
              </h4>
            </div>
          )}
          <TallerCard taller={favouriteTaller} />
        </div>
      </section>
    </section>
  )
}

const mapStateToProps = (state: IAppState) => {
  return {
    datosProgress: selectPercentCompleted(state),
    favouriteTaller: getFavouriteTaller(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    logout: () => dispatch(UserActions.logout()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AreaPrivadaLayout)
