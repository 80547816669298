import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { intl, t, textWithLocale } from '../../../common/i18n/index'
import { InColumns } from '../misPedidos/Facturas'
import styles from './table.module.scss'

interface Row {
  id: string
  paymentType: number
  importePromocion?: string
}

interface Props {
  columns: InColumns[]
  rows: Row[]
  numberOfFacturas: number
  extraColumn?: string
  tableClassName: string
  openModal?: (id: string) => void
  importe?: string
  totalCost?: string
  getFactura?: (numeroDocumento: string) => void
  facturaLoading?: boolean
  importePromocionGlobal?: string
  descripcionesPromocionGlobal?: {
    descripcionPromocionGlobal_es: string
    descripcionPromocionGlobal_ca: string
  }
}

export default function Table({
  columns,
  rows,
  numberOfFacturas,
  extraColumn,
  tableClassName,
  openModal,
  importe,
  totalCost,
  getFactura,
  importePromocionGlobal,
  descripcionesPromocionGlobal,
}: Props) {
  if (rows) {
    return (
      <table
        className={styles[`${tableClassName}_${process.env.GATSBY_LOCALE}`]}>
        <thead>
          <tr>
            {columns.map(({ label }, index) => (
              <th key={index}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows &&
            rows.slice(0, numberOfFacturas).map((row, index) => {
              return (
                <>
                  <tr key={index}>
                    {columns.map((_item, _index) => {
                      if (_item.link) {
                        return (
                          <td className={styles.links} key={_index}>
                            {tableClassName === 'facturas_table' && (
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                className={styles.icon_pdf}
                              />
                            )}
                            <a
                              className={styles.link}
                              href="#"
                              onClick={() => getFactura(row[_item.value])}>
                              {_item.label === ''
                                ? extraColumn
                                : row[_item.value]}
                            </a>
                          </td>
                        )
                      }
                      if (_item.modal) {
                        return (
                          <td key={_index}>
                            <a
                              className={styles.link}
                              href="#"
                              onClick={() => openModal(row.id)}>
                              {_item.label === ''
                                ? extraColumn
                                : row[_item.value]}
                            </a>
                          </td>
                        )
                      }
                      if (_item.currency) {
                        return (
                          <td key={_index}>
                            <p>
                              {row[_item.value]
                                ? intl.formatNumber(row[_item.value], {
                                    style: 'currency',
                                    currency: 'EUR',
                                  })
                                : null}
                            </p>
                            <p>
                              {row.paymentType
                                ? row.paymentType === 0
                                  ? t('mis_pedidos.total_precio')
                                  : t('mis_pedidos.parcial_precio')
                                : null}
                            </p>
                          </td>
                        )
                      }
                      return <td key={_index}>{row[_item.value]}</td>
                    })}
                  </tr>
                  {row.importePromocion && row.importePromocion !== '0.00' ? (
                    <tr key={`${index}aux`}>
                      <td>
                        {t('mis_pedidos.descuento')}{' '}
                        {row[textWithLocale('descripcionPromocion')]}
                      </td>
                      <td></td>
                      <td></td>
                      <td>-{row.importePromocion}</td>
                    </tr>
                  ) : null}
                </>
              )
            })}
          {importePromocionGlobal && Number(importePromocionGlobal) !== 0 ? (
            <tr>
              <td>
                {t('mis_pedidos.descuento')}{' '}
                {
                  descripcionesPromocionGlobal[
                    textWithLocale('descripcionPromocionGlobal')
                  ]
                }
              </td>
              <td></td>
              <td></td>
              <td>
                {intl.formatNumber(-parseFloat(importePromocionGlobal), {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </td>
            </tr>
          ) : null}
          {importe ? (
            <tr>
              <td>{t('mis_pedidos.pagado_web')}</td>
              <td></td>
              <td></td>
              <td>
                {intl.formatNumber(parseFloat(importe), {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </td>
            </tr>
          ) : null}

          {totalCost ? (
            <tr>
              <td>{t('mis_pedidos.precio_total')}</td>
              <td></td>
              <td></td>
              <td>
                {intl.formatNumber(parseFloat(totalCost), {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    )
  }
  return null
}
