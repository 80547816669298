import React from 'react'
import { connect } from 'react-redux'
import Title from '../../Title/index'
import SmallTabs from '../smallTabs/SmallTabs'
import styles from './index.module.scss'
import { IAppState } from '../../../context/context'
import { selectFullName } from '../../../context/selectors/userSelectors'

const Header = ({ userName, device, title, tabs, logout }) => (
  <div className={styles.top_section}>
    <div className={styles.top_section_title_wrapper}>
      <Title text={title} />
      <p>{userName}</p>
    </div>
    <SmallTabs logout={logout} device={device} tabs={tabs} />
  </div>
)

export default connect((state: IAppState) => ({
  userName: selectFullName(state),
}))(Header)
