import { IAppState } from '../context'
import { AreaPrivadaState, key } from '../reducers/areaPrivadaReducer'
import { AreaPrivadaVehicle } from '../../types/AreaPrivada'
import { Aceite } from '../../types/Aceite'
import { Vehicle } from '../../types/Vehiculo'
import { NeumaticoMatricula } from '../../types/Neumatico'
import { ICitaPrevia } from '../../types/CitaPrevia'
import { FavouriteTaller } from '../../types/Taller'

export const selectAreaPrivada = (state: IAppState): AreaPrivadaState =>
  state[key] as AreaPrivadaState
export const isVehicleLoading = (state: IAppState): boolean => {
  const areaPrivada = selectAreaPrivada(state)
  return areaPrivada.vehicleLoading
}

export const getAreaPrivadaVehicle = (
  state: IAppState,
  matricula: string
): AreaPrivadaVehicle => {
  const areaPrivadaState = selectAreaPrivada(state)
  return areaPrivadaState.vehicles.find(
    (item) => item.vehicle.cod_matricula === matricula
  )
}

export const getVehicleByMatricula = (
  state: IAppState,
  matricula: string
): Vehicle | null => {
  const item = getAreaPrivadaVehicle(state, matricula)
  return item?.vehicle || null
}

export const getAceiteByMatricula = (
  state: IAppState,
  matricula: string
): Aceite | null => {
  const item = getAreaPrivadaVehicle(state, matricula)
  return item?.aceite || null
}

export const getNeumaticoByMatricula = (
  state: IAppState,
  matricula: string
): NeumaticoMatricula | null => {
  const item = getAreaPrivadaVehicle(state, matricula)
  return item?.neumatico || null
}

export const getCitasPrevias = (state: IAppState): ICitaPrevia[] => {
  const areaPrivadaState = selectAreaPrivada(state)
  return areaPrivadaState.citasPrevias
}

export const getFavouriteTaller = (state: IAppState): FavouriteTaller => {
  const areaPrivadaState = selectAreaPrivada(state)
  return areaPrivadaState.favouriteTaller
}
