import React, { LegacyRef, SelectHTMLAttributes } from 'react'
import styles from './index.module.scss'
import InfoIcon from '../infoIcon/InfoIcon'
import { v4 as uuidv4 } from 'uuid'
import { t } from '../../i18n'

interface Option {
  value: string | string[] | number
  label: string
}

interface OptionGrouped {
  label: string
  options: Option[]
}

export type Props = {
  options: Option[] | OptionGrouped[]
  labelId?: string
  labelAbove?: boolean
  size?: 'normal' | 'big' | 'small' | 'xl' | 'xxl'
  infoIconTitle?: string
  infoIconText?: string
  disabled?: boolean
  defaultSelected?: string | null
  formRef?: LegacyRef<HTMLSelectElement>
} & Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'>

const makeOptionTag = (option: Option) => (
  <option
    value={option.value}
    key={
      option.value ? option.value.toString() : `${Math.random().toString()}`
    }>
    {option.label}
  </option>
)

const makeOptionGroup = (optGroup: OptionGrouped) => {
  return (
    <optgroup key={optGroup.label} label={optGroup.label}>
      {optGroup.options.map((opt) => {
        return makeOptionTag(opt)
      })}
    </optgroup>
  )
}

const DropDown = ({
  options,
  labelId,
  labelAbove,
  size = 'normal',
  infoIconTitle,
  infoIconText,
  formRef,
  defaultSelected,
  disabled,
  ...props
}: Props) => {
  const _id = props.id
    ? props.id
    : labelId
    ? labelId.replace(/[^0-9a-z]/gi, '-')
    : `unknown-dropdown-${uuidv4()}`
  return (
    <div className={styles.dropdown_container}>
      {!!labelId && !!labelId.trim() && (
        <label htmlFor={_id} className={styles.label}>
          {t(labelId)}
          {(infoIconTitle || infoIconText) && (
            <InfoIcon title={infoIconTitle} text={infoIconText} />
          )}
        </label>
      )}
      <select
        defaultValue={defaultSelected}
        {...props}
        id={_id}
        ref={formRef}
        disabled={disabled}
        className={
          labelId
            ? labelAbove
              ? styles[`label_above_${size}`]
              : styles[`label_left_${size}`]
            : disabled
            ? styles[`default_${size}_disabled`]
            : styles[`default_${size}`]
        }>
        {options.map((option) => {
          if ('options' in option) {
            return makeOptionGroup(option)
          } else {
            return makeOptionTag(option)
          }
        })}
      </select>
    </div>
  )
}

export default DropDown
