import { graphql } from 'gatsby'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import Swal from 'sweetalert2'
import * as api from '../../api/user'
import Layout from '../../components/Layout'
import AreaPrivadaLayout from '../../components/layouts/areaPrivadaLayout/AreaPrivadaLayout'
import Facturas from '../../components/misPedidos/Facturas'
import StandardModal from '../../components/modal/standardModal/StandardModal'
import { SeoData } from '../../types/Seo'
import SpinnerWithText from '../../components/spinnerWithText/SpinnerWithText'
import Table from '../../components/table/Table'
import * as UserActions from '../../context/actions/userActions'
import AppContext from '../../context/context'
import { Product } from '../../context/reducers/cestaReducer'
import { t, textWithLocale } from '../../i18n'
import logic from '../../logic'
import route from '../../utils/route'
import { alertProp } from '../../utils/swal'
import styles from './index.module.scss'

const facturasDropDown: { value: number | 'all'; label: string }[] = [
  { value: 10, label: t('mis_pedidos.ultimas_facturas', { value: '10' }) },
  { value: 20, label: t('mis_pedidos.ultimas_facturas', { value: '20' }) },
  { value: 30, label: t('mis_pedidos.ultimas_facturas', { value: '30' }) },
  { value: 'all', label: t('mis_pedidos.ver_todo') },
]

const PedidosDropDown: { value: number | 'all'; label: string }[] = [
  { value: 10, label: t('mis_pedidos.ultimos_pedidos', { value: '10' }) },
  { value: 20, label: t('mis_pedidos.ultimos_pedidos', { value: '20' }) },
  { value: 30, label: t('mis_pedidos.ultimos_pedidos', { value: '30' }) },
  { value: 'all', label: t('mis_pedidos.ver_todo') },
]

const columnsFacturas = [
  { value: 'formattedDate', label: t('mis_pedidos.fecha') },
  {
    value: 'documentNumber',
    label: t('mis_pedidos.factura_numero'),
    link: true,
  },
  { value: 'operation', label: t('mis_pedidos.operacion') },
  { value: 'shop', label: t('mis_pedidos.taller') },
  { value: 'documentNumber', label: '', link: true },
]

const columnsPedidos = [
  { value: 'formattedDate', label: t('mis_pedidos.fecha') },
  { value: 'id', label: t('mis_pedidos.pedido_numero'), modal: true },
  { value: 'operation', label: t('mis_pedidos.operacion') },
  { value: 'totalPrice', label: t('mis_pedidos.importe'), currency: true },
  { value: '', label: '', modal: true },
]

const columnsProducts = [
  { value: textWithLocale('nombre_producto'), label: t('mis_pedidos.nombre') },
  { value: 'quantity', label: t('mis_pedidos.cantidad') },
  { value: 'price', label: t('mis_pedidos.precio'), currency: true },
  { value: 'rowTotal', label: t('mis_pedidos.total'), currency: true },
]

const errors = ['NOT_EXIST', 'NO_ID_NAV', 'FACTURA_NOT_FOUND', 'ID_NOT_MATCH']
interface IFactura {
  documentNumber: string
  formattedDate: string
  operation: string
  originalDate: string
  shop: string
}

interface IPedido {
  id: string
  paymentType: number
  totalPrice: number
}

export type FacturaPedido = IFactura & IPedido

interface State {
  facturas: IFactura[] | null
  pedidos: IPedido[] | null
  loading: boolean
  productsLoading: boolean
  products: Product[] | null
  selectedIdPedido: string | null
  importe: string | null
  totalCost: string | null
  totalDiscounted: string | null
  facturaLoading: boolean
  error: string | null
  importePromocionGlobal: string | null
  descripcionesPromocionGlobal: {
    descripcionPromocionGlobal_es: string | null
    descripcionPromocionGlobal_ca: string | null
  }
}

const initialState: State = {
  facturas: null,
  pedidos: null,
  loading: false,
  products: null,
  productsLoading: false,
  selectedIdPedido: null,
  importe: null,
  totalCost: null,
  totalDiscounted: null,
  facturaLoading: false,
  error: null,
  importePromocionGlobal: '0.00',
  descripcionesPromocionGlobal: {
    descripcionPromocionGlobal_es: '',
    descripcionPromocionGlobal_ca: '',
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FACTURAS':
      return { ...state, loading: true }
    case 'SET_FACTURAS_OK':
      return {
        ...state,
        loading: false,
        facturas: action.payload.facturas,
        pedidos: action.payload.pedidos,
      }
    case 'SET_FACTURAS_ERROR':
      Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: action.payload.error,
        })
      )
      return { ...state, loading: false }
    case 'SET_PRODUCTS':
      return { ...state, productsLoading: true }
    case 'SET_PRODUCTS_OK':
      return {
        ...state,
        productsLoading: false,
        selectedIdPedido: action.payload.selectedIdPedido,
        importe: action.payload.importe,
        totalCost: action.payload.totalCost,
        importePromocionGlobal: action.payload.importePromocionGlobal,
        descripcionesPromocionGlobal:
          action.payload.descripcionesPromocionGlobal,
        products: action.payload.products,
      }
    case 'SET_PRODUCTS_ERROR':
      Swal.fire(
        alertProp({
          type: 'error',
          title: 'Oops...',
          text: action.payload.error,
        })
      )
      return { ...state, productsLoading: false }
    case 'RESET_PRODUCTS':
      return { ...state, productsLoading: false, products: null }
    case 'GET_FACTURA':
      return {
        ...state,
        facturaLoading: true,
      }
    case 'GET_FACTURA_OK':
      return {
        ...state,
        facturaLoading: false,
      }
    case 'GET_FACTURA_FAILED':
      return {
        ...state,
        facturaLoading: false,
        error: action.payload.error,
      }
    case 'RESET_ERROR':
      return {
        ...state,
        error: null,
      }
    default:
      throw new Error()
  }
}

const Content: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [showModal, setShowModal] = useState(false)
  const { userDispatch, userInfo } = useContext(AppContext)
  const [numberOfFacturas, setNumberOfFacturas] = useState(10)
  const [numberOfPedidos, setNumberOfPedidos] = useState(10)
  const [urlFactura, setUrlFactura] = useState(null)
  const [idFactura, setIdFactura] = useState(null)

  let isCancelled = false

  const retrieveData = () => {
    const { idUser } = userInfo
    dispatch({ type: 'SET_FACTURAS' })
    Promise.all([logic.retrieveFacturas(idUser), logic.retrievePedidos(idUser)])
      .then(([{ facturas }, { pedidos }]) => {
        if (!isCancelled) {
          dispatch({
            type: 'SET_FACTURAS_OK',
            payload: { facturas, pedidos },
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: 'SET_FACTURAS_ERROR',
          payload: { error: err.message },
        })
      })
    if (!userInfo.percentCompleted) {
      userDispatch(UserActions.getUserData())
    }
  }

  useEffect(() => {
    if (userInfo.loggedIn) {
      retrieveData()
    }

    // set isCanclled to true on unmount to avoid changing state when the component is not mounted
    return () => {
      isCancelled = true
    }
  }, [])

  const handleFacturasDropDown = (event) => {
    const { value } = event.target
    if (value === 'all') {
      setNumberOfFacturas(state.facturas.length)
      return
    }
    setNumberOfFacturas(parseInt(value))
  }

  const handlePedidosDropDown = (event) => {
    const { value } = event.target
    if (value === 'all') {
      setNumberOfPedidos(state.pedidos.length)
      return
    }
    setNumberOfPedidos(parseInt(value))
  }

  const retrievePedidoProducts = async (idPedido) => {
    const { idUser } = userInfo
    dispatch({ type: 'SET_PRODUCTS' })
    try {
      const {
        products,
        importe,
        importePromocionGlobal,
        totalCost,
        descripcionPromocionGlobal_es,
        descripcionPromocionGlobal_ca,
      } = await api.retrievePedidoProducts(idUser, idPedido)
      dispatch({
        type: 'SET_PRODUCTS_OK',
        payload: {
          products,
          selectedIdPedido: idPedido,
          importe,
          totalCost,
          importePromocionGlobal,
          descripcionesPromocionGlobal: {
            descripcionPromocionGlobal_es,
            descripcionPromocionGlobal_ca,
          },
        },
      })
    } catch ({ message }) {
      dispatch({ type: 'SET_PRODUCTS_ERROR', payload: message })
    }
  }

  const openModal = (id) => {
    setShowModal(true)
    retrievePedidoProducts(id)
  }

  const closeModal = () => {
    setShowModal(false)
    dispatch({ type: 'RESET_PRODUCTS' })
  }

  const closeModalFactura = () => {
    setUrlFactura(null)
  }

  const getFactura = (numeroDocumento) => {
    setIdFactura(numeroDocumento)
    const { idUser } = userInfo
    dispatch({ type: 'GET_FACTURA' })
    api
      .retrieveFactura(idUser, numeroDocumento)
      .then((res) => {
        if (res instanceof Response) return res.blob()
        throw res
      })
      .then((blob) => {
        dispatch({ type: 'GET_FACTURA_OK' })
        const url = URL.createObjectURL(blob)
        setUrlFactura(url)
      })
      .catch(({ error }) => {
        dispatch({
          type: 'GET_FACTURA_FAILED',
          payload: {
            error: errors.includes(error)
              ? t(`mis_pedidos.${error}_error`)
              : t('mis_pedidos.OTHER_error'),
          },
        })
      })
  }
  if (state.error) {
    Swal.fire(
      alertProp({
        type: 'error',
        title: 'Oops...',
        text: state.error,
      })
    ).then(() => {
      dispatch({
        type: 'RESET_ERROR',
      })
    })
  }

  return (
    <React.Fragment>
      <div className={styles.main_content}>
        {state.loading && <SpinnerWithText text="Cargando..." />}
        {!state.loading && (
          <React.Fragment>
            <Facturas
              columns={columnsPedidos}
              title={t('mis_pedidos.pedidos_web')}
              handleDropDownChange={handlePedidosDropDown}
              dropdownOptions={PedidosDropDown}
              facturas={state.pedidos}
              numberOfFacturas={numberOfPedidos}
              openModal={openModal}
              extraColumn={t('mis_pedidos.detalles')}
              tableClassName="pedidos_table"
            />

            {!state.facturaLoading ? (
              <Facturas
                columns={columnsFacturas}
                title={t('mis_pedidos.factura_web')}
                handleDropDownChange={handleFacturasDropDown}
                dropdownOptions={facturasDropDown}
                facturas={state.facturas}
                numberOfFacturas={numberOfFacturas}
                extraColumn={t('mis_pedidos.consultar')}
                tableClassName="facturas_table"
                getFactura={getFactura}
                facturaLoading={state.facturaLoading}
              />
            ) : (
              <SpinnerWithText text={t('mis_pedidos.cargando_factura')} />
            )}

            <StandardModal
              title={t('mis_pedidos.factura_id', {
                id: idFactura,
              })}
              closeModal={closeModalFactura}
              showModal={!!urlFactura}>
              <iframe src={urlFactura}></iframe>
            </StandardModal>
          </React.Fragment>
        )}
      </div>
      <StandardModal
        title={t('mis_pedidos.pedido_web_modal', {
          value: state.selectedIdPedido,
        })}
        closeModal={closeModal}
        showModal={showModal}>
        {state.loadingProducts && (
          <SpinnerWithText text={t('mis_pedidos.cargando')} />
        )}
        {!state.loadingProducts && state.products && (
          <Table
            columns={columnsProducts}
            rows={state.products}
            numberOfFacturas={state.products && state.products.length}
            tableClassName="products_table"
            importe={state.importe}
            totalCost={state.totalCost}
            importePromocionGlobal={state.importePromocionGlobal}
            descripcionesPromocionGlobal={state.descripcionesPromocionGlobal}
          />
        )}
      </StandardModal>
    </React.Fragment>
  )
}
interface Props {
  data: {
    seoData: SeoData
  }
}
export default function MisPedidos({ data }: Props) {
  return (
    <Layout
      requireLogin={true}
      seoData={data.seoData}
      selector={false}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.mis_pedidos'),
        },
      ]}>
      <AreaPrivadaLayout title={t('mis_pedidos.title')}>
        <Content />
      </AreaPrivadaLayout>
    </Layout>
  )
}

export const pageQuery = graphql`
  query misPedidosQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
