import React from 'react'
import styles from './index.module.scss'
import Flecha from '../../../assets/flecha.svg'
import { Link } from 'gatsby'
import { IAreaPrivadaTab } from '../../../helpers/areaPrivadaTabs'

interface Props {
  tabs: IAreaPrivadaTab[]
  logout: () => void
  device: string
}
const SmallTabs = ({ tabs, logout, device }: Props) => {
  return (
    <ul className={styles[`tabs_${device}`]}>
      {tabs.map(({ route, name, active }, index) => {
        if (index === tabs.length - 1) {
          return (
            <li className={active ? styles.isActive : undefined} key={index}>
              <span onClick={logout} className={styles.link}>
                {name}
              </span>
              {device === 'mobile' && (
                <span className={styles.arrow}>
                  <Flecha />
                </span>
              )}
            </li>
          )
        }
        return (
          <li className={active ? styles.isActive : undefined} key={index}>
            <Link className={styles.link} to={route}>
              {name}
            </Link>
            {device === 'mobile' && (
              <span className={styles.arrow}>
                <Flecha />
              </span>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default SmallTabs
