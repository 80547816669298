import React from 'react'
import DropDown from '../dropDown/DropDown'
import styles from './index.module.scss'
import Table from '../table/Table'
import { FacturaPedido } from '../../pages/mis-pedidos'

export interface InColumns {
  label: string
  value: string
  link?: boolean
  modal?: boolean
  currency?: boolean
}
export interface InFacturasProps {
  facturas: FacturaPedido[]
  dropdownOptions: { value: number | 'all'; label: string }[]
  handleDropDownChange: (event: React.FormEvent<HTMLSelectElement>) => void
  title: string
  columns: InColumns[]
  numberOfFacturas: number
  openModal?: (id: string) => void
  extraColumn: string
  tableClassName: string
  getFactura?: (numeroDocumento: string) => void
  facturaLoading?: boolean
}

export default function Facturas({
  facturas,
  dropdownOptions,
  handleDropDownChange,
  title,
  ...props
}: InFacturasProps) {
  if (facturas) {
    return (
      <div className={styles.facturas_container}>
        <div className={styles.title}>
          <h2>{title}</h2>
          {facturas.length > 5 ? (
            <DropDown
              size="xxl"
              labelAbove={true}
              onChange={handleDropDownChange}
              options={dropdownOptions}
            />
          ) : null}
        </div>
        {!facturas.length ? (
          <p className={styles.no_facturas}>No hay {title} para mostrar</p>
        ) : null}
        <Table rows={facturas} {...props} />
      </div>
    )
  }
  return null
}
