import React from 'react'
import StarIcon from '../../../assets/images/area_privada/icon_estrella.svg'
import LocationIcon from '../../../assets/images/area_privada/icon_ubicacion5.svg'
import Phone from '../../../assets/images/area_privada/icon_telefo.svg'
import styles from './index.module.scss'
import { t } from '../../../i18n'
import { FavouriteTaller } from '../../../types/Taller'

interface Props {
  taller: FavouriteTaller
}
export default function TallerCard({ taller }: Props) {
  if (taller) {
    return (
      <div className={styles.taller_card}>
        <div className={styles.taller_card_top}>
          <span className={styles.location_icon}>
            <LocationIcon />
          </span>
          <span className={styles.taller_card_top_name}>
            Rodi {taller.alias}
          </span>
          <span>
            <StarIcon />
          </span>
        </div>
        <div className={styles.taller_info_wrapper}>
          <div className={styles.taller_card_address}>
            <span>{taller.direccion}</span>
            <span>
              {taller.provincia} ({taller.poblacion}) - {taller.cpostal}
            </span>
            <a className={styles.phone} href={`tel:${taller.telefono}`}>
              <Phone />
              {taller.telefono}
            </a>
          </div>
          <div className={styles.taller_card_weekdays}>
            <span>{t('taller-card.lunes_viernes')}:</span>
            <span>
              {taller.horario_lunvie_m_front} y {taller.horario_lunvie_t_front}
            </span>
          </div>
          <div className={styles.taller_card_weekends}>
            <span>{t('taller-card.sabado')}:</span>
            <span>{taller.horario_sab_m_front}</span>
          </div>
        </div>
      </div>
    )
  }
  return null
}
